import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		primary_color: "#cfe700"
		//primary_color: "#9fa8da"
	},
	mutations: {},
	actions: {},
	modules: {}
});
