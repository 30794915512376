import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import VueLazyLoad from "vue-lazyload";

Vue.use(VueLazyLoad);
Vue.use(VueAxios, axios);
//import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	//vuetify,
	render: h => h(App)
}).$mount("#app");
