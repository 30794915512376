import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("../views/Home.vue"),
	},
	// league
	{
		path: "/schedule",
		name: "schedule",
		component: () => import("../views/league/Schedule.vue"),
	},
	{
		path: "/team_ranking",
		name: "team_ranking",
		component: () => import("../views/league/TeamRanking.vue"),
	},
	{
		path: "/award",
		name: "award",
		component: () => import("../views/league/Award.vue"),
	},
	{
		path: "/player_ranking",
		name: "player_ranking",
		component: () => import("../views/league/PlayerRanking.vue"),
	},
	{
		path: "/annual_player_ranking",
		name: "annual_player_ranking",
		component: () => import("../views/league/AnnualPlayerRanking2023.vue"),
	},
	{
		path: "/annual_player_ranking2023",
		name: "annual_player_ranking2023",
		component: () => import("../views/league/AnnualPlayerRanking2023.vue"),
	},
	{
		path: "/annual_player_ranking2022",
		name: "annual_player_ranking2022",
		component: () => import("../views/league/AnnualPlayerRanking2022.vue"),
	},
	{
		path: "/annual_player_ranking2019",
		name: "annual_player_ranking2019",
		component: () => import("../views/league/AnnualPlayerRanking2019.vue"),
	},
	{
		path: "/pentathlon",
		name: "pentathlon",
		component: () => import("../views/league/Pentathlon.vue"),
	},
	// tournament
	{
		path: "/tournament/",
		name: "tournament",
		component: () => import("../views/tournament/Tournament2023.vue"),
	},
	{
		path: "/tournament/2023/",
		name: "tournament2023",
		component: () => import("../views/tournament/Tournament2023.vue"),
	},
	{
		path: "/tournament/2022/",
		name: "tournament2022",
		component: () => import("../views/tournament/Tournament2022.vue"),
	},
	{
		path: "/tournament/2021/",
		name: "tournament2021",
		component: () => import("../views/tournament/Tournament2021.vue"),
	},
	{
		path: "/masters/",
		name: "masters",
		component: () => import("../views/masters/Masters2024.vue"),
	},
	{
		path: "/masters/2024/",
		name: "masters2024",
		component: () => import("../views/masters/Masters2024.vue"),
	},
	{
		path: "/masters/2023/",
		name: "masters2023",
		component: () => import("../views/masters/Masters2023.vue"),
	},
	{
		path: "/masters/2020/",
		name: "masters2020",
		component: () => import("../views/masters/Masters2020.vue"),
	},
	// jdo tournament
	{
		path: "/jdo/tournament/",
		name: "jdo_tournament",
		component: () => import("../views/jdo/Tournament2024.vue"),
	},
	{
		path: "/jdo/tournament2024/",
		name: "jdo_tournament2024",
		component: () => import("../views/jdo/Tournament2024.vue"),
	},
	{
		path: "/jdo/tournament2023/",
		name: "jdo_tournament2023",
		component: () => import("../views/jdo/Tournament2023.vue"),
	},
	{
		path: "/jdo/tournament2022/",
		name: "jdo_tournament2022",
		component: () => import("../views/jdo/Tournament2022.vue"),
	},
	// about
	{
		// 会員リスト
		path: "/members",
		name: "members",
		component: () => import("../views/about/Members2024.vue"),
	},
	{
		path: "/members2024",
		name: "members2024",
		component: () => import("../views/about/Members2024.vue"),
	},
	{
		path: "/members2023",
		name: "members2023",
		component: () => import("../views/about/Members2023.vue"),
	},
	{
		path: "/members2022",
		name: "members2022",
		component: () => import("../views/about/Members2022.vue"),
	},
	{
		// チーム紹介
		path: "/teams",
		name: "teams",
		component: () => import("../views/about/Team.vue"),
	},
	{
		// ベニュー紹介
		path: "/venues",
		name: "venues",
		component: () => import("../views/about/Venue.vue"),
	},
	{
		// 各種ダウンロード
		path: "/download",
		name: "download",
		component: () => import("../views/about/Download.vue"),
	},
	{
		// 理事会・事務局
		path: "/council",
		name: "council",
		component: () => import("../views/about/Council.vue"),
	},
	{
		// プライバシーポリシー
		path: "/privacy_policy",
		name: "privacy_policy",
		component: () => import("../views/about/PP.vue"),
	},
	// contact
	/*{
		// thanksページ
		path: "/contact_thanks",
		name: "contact_thanks",
		component: () => import("../views/about/ContactThanks.vue")
	}*/
	{
		// プライバシーポリシー
		path: "*",
		name: "404",
		component: () => import("../views/404.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
